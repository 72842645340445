<template>
  <div>
    <div class="container">
      <h1>您当前权限不够</h1>
      <p>
        您当前权限只能访问<span>{{ permiss }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      permiss: ''
    }
  },
  created () {
    const webspan = localStorage.getItem('quanxian')
    console.log(webspan)
    if (webspan == 2) {
      this.permiss = '直播'
      this.$router.push({path:'/live'})
    } else if (webspan == 3) {
      this.permiss = '医知医汇'
      this.$router.push({path:'/homearticle'})
    } else if (webspan == 4) {
      this.permiss = '超声e站'
      this.$router.push({path:'/homearticle'})
    } else if (webspan == 5) {
      this.permiss = '医知医汇.net'
      this.$router.push({path:'/yizhi'})
    }else if (webspan > 819202100 && webspan < 819999999){
      this.permiss = '直播'
      this.$router.push({path:'/live'})
    }
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
  margin: 30px 0;
}
p {
  line-height: 30px;
  margin-bottom: 10px;
  text-indent: 2em;
  padding: 10px;
  color: #409eff;
  font-size: 20px;
}
.logout {
  color: #409eff;
}
</style>